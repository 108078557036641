import { ApiInstance } from '@/api/ApiInstance';
import { configuration } from '@/config';
import { Token } from '@/modules/session/domain/Types';
import { AdvertiserListingResponse } from '../rest/response/AdvertiserListingResponse';
import { AuthorizationHeaderBuilder } from '@/api/AuthorizationHeaderBuilder';
// @ts-ignore
import { AdvertiserSubmitEvent } from '../components/AdvertiserForm.vue';
import { Id } from '@/modules/user/domain/Types';
import { Advertiser } from '../domain/Advertiser';
import { AdvertiserSearchCriteria } from '../domain/AdvertiserSearchCriteria';

export class AdvertiserService {
    private static readonly api = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/advertiser`);

    public static async getAllAdvertisers(token: Token) {
        const { data: advertisers } = await this.api.get<Advertiser[]>('/all', {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return advertisers;
    }

    public static async getAdvertisersWith(searchCriteria: AdvertiserSearchCriteria, token: Token) {
        const { data: advertisersReponse } = await this.api
            .get<AdvertiserListingResponse>(`/?search=name:${searchCriteria.name}&page=${searchCriteria.page}`, {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        if (!advertisersReponse._embedded) {
            return { advertisers: [], pageCount: 1};
        }

        return { advertisers: advertisersReponse._embedded.advertisers, pageCount: advertisersReponse.page.totalPages };
    }

    public static async create(advertiser: AdvertiserSubmitEvent, token: Token) {
        await this.api.post('/', advertiser, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async getAdvertiserBy(id: Id, token: Token) {
        const { data: advertiser } = await this.api.get<Advertiser>(`/${id}`, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return advertiser;
    }

    public static async update(advertiser: AdvertiserSubmitEvent, advertiserId: Id, token: Token) {
        await this.api.put(`/${advertiserId}`, advertiser, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }
}
