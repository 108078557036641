







































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import {validateEmail2} from '@/utils/ValidateEmail';
import {Advertiser} from '../domain/Advertiser';
import { PortalZone } from '../../portal/domain/PortalZone';
import PortalZoneTransfer from '@/modules/portal/components/PortalZoneTransfer.vue';
import { Portal } from '@/modules/portal/domain/Portal';

export interface AdvertiserSubmitEvent {
    name: string;
    email: string | null;
    address: string | null;
    phone: string | null;
    managerName: string | null;
    accountingEmail: string | null;
    zones: PortalZone[] | [];
}

@Component({
    components: {Field, PortalZoneTransfer},
})

export default class AdvertiserForm extends Vue {
    @Prop() private portals!: Portal[];
    private selectedZones: PortalZone[] | PortalZone[] = [];
    private hasSumbitted: boolean = false;
    private validateEmail = validateEmail2;
    private isLoadingZones: boolean = false;

    public rules =  {
        name: [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        email: [
            {
                validator: this.validateEmail,
                message: 'Le courriel est invalide',
                trigger: 'blur',
            },
        ],
        accountingEmail: [
            {
                validator: this.validateEmail,
                message: 'Le courriel est invalide',
                trigger: 'blur',
            },
        ],
        zones: [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
    };

    @Prop({default: {id: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        managerName: '',
        accountingEmail: '',
        zones: []} }) private readonly advertiser!: Advertiser;

    @Watch('advertiser')
    private onAdvertiserUpdate(advertiser: Advertiser) {
        this.setZonesAs(advertiser.zones);
    }

    private submit() {
        this.hasSumbitted = true;
        const form: any = this.$refs.advertiserForm;
        form.validate((valid: boolean) => {
            if (valid) {
                const advertiserSubmitEvent: AdvertiserSubmitEvent = {
                    name: this.advertiser.name,
                    email: this.advertiser.email,
                    address: this.advertiser.address,
                    phone: this.advertiser.phone,
                    managerName: this.advertiser.managerName,
                    accountingEmail: this.advertiser.accountingEmail,
                    zones: this.advertiser.zones,
                };
                this.$emit('submit', advertiserSubmitEvent);

                if (this.advertiser.id === '') {
                    form.resetFields();
                    const transfer: any = this.$refs.transfer;
                    transfer.$children[0].value.splice(0, transfer.$children[0].value.length);
                }
            } else {
                return false;
            }
        });
    }

    get buttonIcon() {
        return this.advertiser.id !== '' ? 'el-icon-edit' : 'el-icon-add';
    }

    get buttonText() {
        return this.advertiser.id !== '' ? 'Modifier' : 'Ajouter';
    }

    get buttonType() {
        return this.advertiser.id !== '' ? 'warning' : 'success';
    }

    private setZonesAs(zones: PortalZone[]) {
        this.selectedZones = zones;
        this.advertiser.zones = zones;
    }

    private onZonesUpdate(zones: PortalZone[], portalId: string) {
        const filterSelectedZones = this.selectedZones.filter((s) => s.portalId !== portalId);
        this.setZonesAs(filterSelectedZones.concat(zones));
    }
}
