import { render, staticRenderFns } from "./AdvertiserForm.vue?vue&type=template&id=2251f8e0&scoped=true&"
import script from "./AdvertiserForm.vue?vue&type=script&lang=ts&"
export * from "./AdvertiserForm.vue?vue&type=script&lang=ts&"
import style0 from "./AdvertiserForm.vue?vue&type=style&index=0&id=2251f8e0&lang=scss&scoped=true&"
import style1 from "./AdvertiserForm.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2251f8e0",
  null
  
)

export default component.exports