








import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import Card from '@/components/card/Card.vue';
import AdvertiserForm, { AdvertiserSubmitEvent } from '../components/AdvertiserForm.vue';
import { AdvertiserService } from '../services/AdvertiserService';
import { PortalService } from '@/modules/portal/services/PortalService';
import { PortalZone } from '@/modules/portal/domain/PortalZone';
import { Advertiser } from '@/modules/advertiser/domain/Advertiser';
import { Portal } from '@/modules/portal/domain/Portal';

@Component({
    components: {Page, Card, AdvertiserForm},
})
export default class CreateAdvertiser extends Vue {
    private portals: Portal[] | Portal[] = [];
    private isLoading: boolean = false;
    private hasError: boolean = false;
    private advertiser: Advertiser | Advertiser = {
        id: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        managerName: '',
        accountingEmail: '',
        zones: [],
    };

    private async mounted() {
        this.isLoading = true;
        try {
            const portals = await PortalService.getAll(this.$store.getters['sessionStore/token']);
            const zones = await PortalService.getZonesForUser(this.$store.getters['sessionStore/token']);
            await portals.forEach((e, index) => {
                portals[index].zones = zones.filter((z: PortalZone) => z.portalId === e.id);
            });
            this.portals = portals;
        } catch {
            this.hasError = true;
        }
        this.isLoading = false;
    }

    private async createAdvertiser(advertiser: AdvertiserSubmitEvent) {
        this.isLoading = true;
        try {
            await AdvertiserService.create(advertiser, this.$store.getters['sessionStore/token']);
            this.$notify.success({title: 'SUCCÈS', message: 'Les informations ont été mises à jour !'});
        } catch {
            this.$notify.error({
                title: 'ERREUR',
                message: 'Erreur lors de l\'ajout. Veuillez vérifier vos champs et reessayer.',
            });
        }
        this.isLoading = false;
    }
}
